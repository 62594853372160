<template>
  <div class="login_container">

    <div class="form-container">
      <div style="display: flex;margin-top: 15vh;">
        <img src="../../assets/login_logo.png" style="width: 48px;height: auto;object-fit: contain;">
        <p style="color: #333;font-size: 28px;font-weight: bold;margin-left: 15px;">欢迎登录参好会系统</p>
      </div>
      <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-position="top" label-width="90px"
        style="width: 45%;">
        <el-form-item prop="organizer_email" label="账号">
          <el-input v-model="ruleForm2.organizer_email" type="text" auto-complete="off" placeholder="账号" />
        </el-form-item>
        <el-form-item label="账户类型">
          <el-select v-model="ruleForm2.type" style="width: 100%">
            <el-option label="组织者" value="organizer"></el-option>
            <el-option label="品牌客户" value="partner"></el-option>
            <el-option label="嘉宾" value="guest"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="organizer_password" :label="ruleForm2.type != 'partner' ? '密码' : '验证码'">
          <div style="display: flex;">
            <el-input v-model="ruleForm2.organizer_password" v-if="ruleForm2.type != 'partner'" auto-complete="off"
              placeholder="密码" type="password" style="flex: 1;" />
            <el-input v-model="ruleForm2.organizer_password" v-else auto-complete="off" placeholder="请输入邮箱验证码"
              style="flex: 1;" />
            <el-button type="primary" size="small" v-if="ruleForm2.type == 'partner'" style="margin-left: 5px;"
              @click="doGetOPTCode">获取验证码</el-button>
          </div>
        </el-form-item>
      </el-form>

      <div class="btn-login" @click="submit">
        登录
      </div>
    </div>
    <div class="bottom-circle"></div>
  </div>
</template>

<script>
import { loginByOrganizer, loginByGuest, loginByPartner, createLoginOPT } from "../../api/api";
import { setUserId, setUserName, setUserType } from "../../utils/store";
export default {
  name: "Login",
  data() {
    return {
      ruleForm2: {
        organizer_email: "",
        organizer_password: "",
        type: "organizer",
      },
      rules2: {
        organizer_email: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        organizer_password: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择账户类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {

    doGetOPTCode() {
      let email = this.ruleForm2.organizer_email
      if (!email) {
        this.$message.warning('请输入账号')
        return
      }
      createLoginOPT(email).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.$message.success('验证码已经成功发送到您邮箱，请查收～')
        } else {
          this.$message.success(result.msg)
        }
      })
    },

    submit() {
      this.$refs.ruleForm2.validate((val) => {
        if (val) {
          if (this.ruleForm2.type === "guest") {
            loginByGuest({
              email: this.ruleForm2.organizer_email,
              password: this.ruleForm2.organizer_password,
            }).then((res) => {
              let result = res.data;
              if (result.code == 0) {
                setUserId(result.data._id.$id);
                setUserName(result.data.name);
                setUserType("guest");
                this.$UserData.user_name = result.data.name;
                this.$router.push("/guest/sessions");
              } else {
                this.$message.error(result.msg);
              }
            });
          } else if (this.ruleForm2.type === 'organizer') {
            loginByOrganizer(
              this.ruleForm2.organizer_email,
              this.ruleForm2.organizer_password
            ).then((res) => {
              let result = res.data;
              if (result.code == 0) {
                setUserId(result.data._id.$id);
                setUserName(result.data.title);
                setUserType("organizer");
                this.$UserData.user_name = result.data.title;
                this.$router.push("/home/tabsList");
              } else {
                this.$message.error(result.msg);
              }
            });
          } else if (this.ruleForm2.type === 'partner') {
            loginByPartner(
              this.ruleForm2.organizer_email,
              this.ruleForm2.organizer_password
            ).then((res) => {
              let result = res.data;
              if (result.code == 0) {
                setUserId(result.data._id.$id);
                setUserName(result.data.title);
                setUserType("partner");
                this.$UserData.user_name = result.data.name;
                this.$router.push("/home/partner_meetings");
              } else {
                this.$message.error(result.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep(.el-form--label-top) .el-form-item__label {
  padding: 0px
}

::v-deep(.el-form-item) {
  margin-bottom: 14px;
}

.btn-login {
  cursor: pointer;
  margin-top: 20px;
  border-radius: 24px;
  text-align: center;
  width: 45%;
  background-color: #0074FF;
  padding: 12px 0px;
  color: white;
  font-size: 16px;
}

.login_container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/login_bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;

  .form-container {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    background-color: white;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-circle {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #1A50FF;
    right: -150px;
    bottom: -150px
  }
}
</style>
